import {
	Card,
	CardImg,
	Row,
	Col,
	Container,
	jumbotron,
	Button,
	label,
	input,
	ul,
	li,
	Nav,
	Navbar,
} from "react-bootstrap";
import logo from "./gembit.png";
import bg from "./bg.png";
import { FiCheckCircle } from "react-icons/fi";

const Home = () => {
	return (
		<Container
			className="mt-0 ml-0 pl-0"
			style={{
				backgroundSize: "cover",
				backgroundImage: `url(${bg})`,
				backgroundAttachment: "fixed",
				maxWidth: "100%",
				//height: "100vh",
			}}
		>
			<Row>
				<jumbotron className="jumbotron-fluid" style={{ padding: "0" }}>
					<Container fluid className="p-5">
						<h1 className="text-white btx">IDEAS FOR CONVENIENCE</h1>
						<p className="mt-4 text-white">
							Solutions for your home automation needs
						</p>
					</Container>
				</jumbotron>
			</Row>
			<Row className="mt-4">
				<div className="rounded bg-light p-3 mx-auto altw text-start">
					<Row>
						<Col className="p-5">
							<h1 class="underlined">Header Text</h1>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
								sagittis convallis leo, quis condimentum eros vestibulum non.
								Sed purus velit, egestas id pellentesque ut, suscipit eu neque.
								Mauris feugiat consequat sapien, sit amet ultrices eros. Nam
								tristique lorem at urna vestibulum, non lobortis quam placerat.
								Etiam pharetra diam id urna pellentesque congue. Sed varius
								congue mauris at porttitor. Suspendisse eleifend sed diam ornare
								elementum. Etiam ante purus, pellentesque ac sem ut, auctor
								condimentum elit. Orci varius natoque penatibus et magnis dis
								parturient montes, nascetur ridiculus mus. Aenean placerat odio
								sit amet ipsum pellentesque, in fringilla magna rutrum. Nunc
								eros tellus, lobortis vel rutrum sit amet, aliquet quis massa.
								Morbi sagittis, lectus et vestibulum rhoncus, metus nunc dapibus
								enim, a faucibus nibh arcu in enim. Cras tellus odio, placerat
								at egestas a, lacinia eget felis. Ut tempus quam ante, ut
								lacinia neque dictum nec. Nam porttitor, elit sed sodales
								porttitor, tortor risus ultricies nunc, ac semper massa metus at
								massa.
							</p>
						</Col>
						<Col>col2</Col>
					</Row>
				</div>
			</Row>
		</Container>
	);
};

export default Home;
