import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import Navigation from './Navigation';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import About from './About';
import LineChart from './LineChart';

function App() {
  return (
<div className="App">
<Navigation />
<Routes>
        <Route path="/" element={<Home />} />
        <Route path="About" element={<About />} />
        <Route path="LineChart" element={<LineChart />} />
      </Routes>
</div>  );
}

export default App;
