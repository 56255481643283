import {
	Nav,
	Navbar,
	Container,
	Button,
	NavLink,
	NavDropdown,
} from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import CreateTempChart from "./LineChart";

function Navigation() {
	return (
		<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="">
			<Container>
				<Navbar.Brand href="/">GemBit IoT Systems</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto"></Nav>
					<Nav>
						<Nav.Link href="/">Home</Nav.Link>
						<Nav.Link href="/LineChart">Temperatures</Nav.Link>
						<Nav.Link href="/About">About</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Navigation;
