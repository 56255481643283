import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Line, CategoryScale, Chart } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { useState, useEffect } from "react";

const LineChart = () => {
	const [chart, setChart] = useState([]);
	const [currentTemp, setTemperature] = useState([]);
	const [currentHum, setHumidity] = useState([]);

	useEffect(() => {
		fetchData();
		const t = setInterval(fetchData, 10000);
		return () => clearInterval(t); // clear
	}, []);

	const fetchData = async () => {
		try {
			const res = await fetch("https://www.gembit.hu/temperatures/api.jsonp");
			var data = await res.json();
			setChart(data);
			setTemperature(data.temp[data.temp.length - 1]);
			setHumidity(data.hum[data.hum.length - 1]);
		} catch (Exception) {
			alert(Exception);
		}
	};

	var cdata = {
		labels: chart.time,
		showXlabels: 10,
		datasets: [
			{
				label: "Temperatures",
				backgroundColor: "rgb(255, 99, 132)",
				borderColor: "rgb(255, 99, 132)",
				data: chart.temp,
				lineTension: 0.1,
			},
		],
	};

	var hdata = {
		labels: chart.time,
		showXlabels: 10,
		datasets: [
			{
				label: "Humidity",
				backgroundColor: "rgb(30, 99, 132)",
				borderColor: "rgb(30, 99, 132)",
				data: chart.hum,
				lineTension: 0.1,
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			y: {},
			x: {
				ticks: {
					/* callback: function(val, index) {
            return index % 100 === 0 ? this.getLabelForValue(val) : '';
          },*/
					beginAtZero: true,
					display: false,
				},
			},
		},
		plugins: {
			legend: {
				display: true,
			},

			title: {
				display: false,
				text: "Room temperature data",
			},

			bezierCurve: true,
		},
	};

	return (
		<Container>
			<Row className="mt-2">
				<Col>
					<Card>
						<Card.Header className="bg-warning text-black">
							Hőmérséklet:
						</Card.Header>
						<Card.Body>{currentTemp} °C</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card>
						<Card.Header className="bg-info text-black">
							Páratartalom:
						</Card.Header>
						<Card.Body>{currentHum}% Rh</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Row style={{ height: 300 }}>
					<Line data={cdata} options={options} />
				</Row>
				<Row style={{ height: 300 }}>
					<Line data={hdata} options={options} />
				</Row>
			</Row>
		</Container>
	);
};

export default LineChart;
